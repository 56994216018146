import React from "react";
import { View, Dimensions, Platform, Pressable } from "react-native";
import { Feather } from '@expo/vector-icons';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('screen');
let scale = 1

// Adjust header elements for viewing the web portal on smaller devices
if (width < 800 ) {
    scale = .7
  }

  
const Footer = (props) => {

const navigation = useNavigation();
    //const { validKPIs, organization, admin } = props
    return (
        <View style={{ paddingHorizontal: wp(2.7), paddingVertical: hp(.1), paddingTop: hp(.1), backgroundColor: '#000', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <View style={{flexDirection: 'column'}}>
                <Pressable style={styles.button} onPress={() => {navigation.navigate('SubmitReport', props)}}>
                    <Feather name='send' size={40 * scale} color={'#fff'} />
                </Pressable>
                    <CustomText size={12} style={{color: '#fff'}}>Submit Report</CustomText>
            </View>
            <View style={{flexDirection: 'column'}}>
                <Pressable style={styles.button}onPress={() => {let page = (props.admin === true || props.admin === 'true') ? 'AdminFilterHome' : 'PreviousReport'; navigation.navigate(page, props)}}>
                    <Feather name='inbox' size={40 * scale} color={'#fff'} />
                </Pressable>
                    <CustomText size={12} style={{color: '#fff'}}>View Reports</CustomText>
            </View>
            {(props.admin === true || props.admin === 'true') && <View style={{flexDirection: 'column'}}>
                <Pressable style={styles.button}onPress={() => {navigation.navigate('AdminOrganizationDashboard', props)}}>
                    <Feather name='settings' size={40 * scale} color={'#fff'} />
                </Pressable>
                    <CustomText size={12} style={{color: '#fff'}}>Manage Tags</CustomText>
            </View>}
            <View style={{flexDirection: 'column'}}>
                <Pressable style={styles.button}onPress={() => {navigation.navigate('AnalyticsScreen', props)}}>
                    <Feather name='bar-chart' size={40 * scale} color={'#fff'} />
                </Pressable>
                    <CustomText size={12} style={{color: '#fff'}}>Analytics</CustomText>
            </View>
            {((Platform.OS === "web" && width >= 800) && (props.admin === true || props.admin === 'true')) && <View style={{flexDirection: 'column'}}>
                <Pressable style={styles.button}onPress={() => {navigation.navigate('UserManagement', props)}}>
                    <Feather name='users' size={40 * scale} color={'#fff'} />
                </Pressable>
                    <CustomText size={12} style={{color: '#fff'}}>User Management</CustomText>
            </View>}
        </View>
    )    
}

const styles = {
    button: {
        cursor: 'pointer',
        alignItems: 'center',
        marginTop: 5
    }
}

export default Footer;