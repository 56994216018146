import React, { useState, useEffect } from "react"
import { API } from 'aws-amplify';
import { View, Pressable, Dimensions, SafeAreaView, Platform, FlatList, ImageBackground } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";

import Util from "../util/util.js"

const screenWidth = Dimensions.get('window').width;

const Home = (props) => {
    const { organization } = props.route.params;
    const [validKPIs, setValidKPIs] = useState(() => null)

    //Get valid KPIs
    const getValidKPIs = async (_organization) => {
        const requestData = {
            queryParams: {
                organization: _organization,
                value_type: "KPI"
            }
        }
        try{ 
            const data = await Util.get('Resty','/items/organization_configuration/value_type', requestData)
            return(data.items) // This is could perhaps lead to undefined behavior :/
        }
        catch(error){
            Util.printAPIError(error)
        }
        return([])
    }

    useEffect(() => {
        (async () => {
            const validKPIs = await getValidKPIs(organization)
            setValidKPIs(validKPIs)
          })();
    }, [])

    const buttonList = [
        {
            "buttonText":"Submit Report",
            "navigation":"SubmitReport"
        },
        {
            "buttonText":"Report Analytics",
            "navigation":"AnalyticsScreen"
        },
        {
            "buttonText":"View Reports",
            "navigation":"PreviousReport"
        },
    ]

    const renderButtons = (buttonText, buttonNavigation) => {
        return(<Pressable onPress={() => { props.navigation.navigate(buttonNavigation, {validKPIs: validKPIs, organization: organization, admin: false}) }}>
            <View style={webStyles.orangeContainer}>
                <CustomText style={{ textAlign: 'center' }} font='semiBold' size={Platform.OS === 'web' || screenWidth >= 800 ? 40 : 30} color={'#000'}>{buttonText}</CustomText>
            </View>
        </Pressable>)
    }

    return (
        <View style={Platform.OS === 'web' ? webStyles.container : mobileStyles.container}>
            {/* Global header  */}
            <Header organization={organization} admin={false} />
            <ImageBackground source={require('../assets/images/background1.png')} style={webStyles.backgroundImageTop}/>
                <SafeAreaView style={{ flex: 1, paddingHorizontal: wp(6), marginTop: hp(5.9) }}>
                    <FlatList
                        data={buttonList}
                        renderItem={({item}) => {
                            return(renderButtons(item.buttonText, item.navigation))
                        }}
                        numColumns={1}
                    />
                </SafeAreaView>
                <ImageBackground source={require('../assets/images/background1.png')} style={webStyles.backgroundImage}/>
                <Footer  admin={false}/>
        </View>
    )
}

export default Home;