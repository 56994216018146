import React, { useState, useEffect } from "react"
import { View, Pressable, SafeAreaView, Dimensions, Platform, FlatList, ImageBackground } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Auth, API } from 'aws-amplify';
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Util from "../util/util.js"

import webStyles from "../style/webStyles.js"
import mobileStyles from "../style/mobileStyles.js";
import { CalendarUtils } from "react-native-calendars";

const screenWidth = Dimensions.get('window').width;

const HomePremium = (props) => {
    const { organization, userManagement } = props.route.params;
    const [validKPIs, setValidKPIs] = useState(() => null)

    //Get valid KPIs
    const getValidKPIs = async (_organization) => {
        const requestData = {
            queryParams: {
                organization: _organization,
                value_type: "KPI"
            }
        }
        try{ 
            const data = await Util.get('Resty','/items/organization_configuration/value_type', requestData)
            return(data.items) // This is could perhaps lead to undefined behavior :/
        }
        catch(error){
            Util.printAPIError(error)
        }
        return([])
    }

    useEffect(() => {
        console.log("organization: ", organization);
        (async () => {
            const validKPIs = await getValidKPIs(organization)
            setValidKPIs(validKPIs)
          })();
    }, [])

    const buttonList = [
        {
            "buttonText":"Report Analytics",
            "navigation":"AnalyticsScreen"
        },
        {
            "buttonText":"View Reports",
            "navigation":"AdminFilterHome"
        },
        {
            "buttonText":"Manage Report Tags",
            "navigation":"AdminOrganizationDashboard"
        },
        {
            "buttonText":"Submit Report",
            "navigation":"SubmitReport"
        },
    ]

    if (Platform.OS === 'web' && userManagement) {
        buttonList.push({
            "buttonText":"User Management",
            "navigation":"UserManagement"
        })
    }

    const renderButton = (buttonText, buttonNavigation) => {
        return(<Pressable onPress={() => { props.navigation.navigate(buttonNavigation, {validKPIs: validKPIs, organization: organization, admin: true}) }}>
            <View style={[webStyles.orangeContainer]}>
                <CustomText style={{ textAlign: 'center', marginVertical: hp(.2), paddingVertical: hp(1), paddingHorizontal: wp(1) }} font='semiBold' size={Platform.OS === 'web' || screenWidth >= 800 ? 40 : 28} color={'#000'}>{buttonText}</CustomText>
            </View>
        </Pressable>)
    }

    return (
        <View style={Platform.OS === 'web'? webStyles.container : mobileStyles.container}>
            {/* Global header  */}
            <Header organization={organization} admin={true}/>
            <ImageBackground source={require('../assets/images/background1.png')} style={webStyles.backgroundImageTop}/>
            <SafeAreaView style={{flex: 1, paddingHorizontal: wp(6), marginTop: hp(5.9)}}>
                <FlatList
                    data={buttonList}
                    renderItem={({item}) => {
                        return(renderButton(item.buttonText,item.navigation))
                    }}
                    numColumns={1}
                />
            </SafeAreaView>
            <ImageBackground source={require('../assets/images/background1.png')} style={webStyles.backgroundImage}/>
            <Footer admin={true} validKPIs={validKPIs} organization={organization} />
        </View>
    )
}

export default HomePremium;