import { Platform, Dimensions } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
const AWS = require('aws-sdk')
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import  * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { uploadData } from 'aws-amplify/storage';
import {generateClient} from 'aws-amplify/api';
import * as ImageManipulator from 'expo-image-manipulator';

import { sendEmailNotification } from './lambdaUtil';
import { v4 as uuidv4 } from 'uuid';

var buffer = require('buffer').Buffer;

const screenWidth = Dimensions.get('window').width



//Util.js contains code that is duplicated many times, so for better readibility this code should be used


//Utility function for printing errors related to calling API. Very repetitive
class Util{

    static calculateFontSize = (text, baseValue, userFontSize) => {
      const minFontSize = 10;
      const maxFontSize = 24;
      const emailLength = text.length
      const base = screenWidth >= 1000 ? baseValue : baseValue * (screenWidth / 1000)
  
      if (emailLength < base) { return maxFontSize }
  
      const excessLength = emailLength - base;
      let decayFactor = userFontSize < 14 ? .032 : userFontSize < 15 ? .035 : userFontSize < 16 ? .038 : 0.04; // Adjust this factor to control the decay rate
  
      decayFactor *= screenWidth >= 1000 ? 1 : (500 / screenWidth)
  
      const newSize = maxFontSize * Math.exp(-decayFactor * excessLength);
  
      return(Math.min(Math.max(minFontSize, newSize), maxFontSize))
    }
    static printAPIError = (error) => {
        console.log("\nERRORRRRRRRRRRRRRRRRRR")
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
    }

    static textOnly = (chips) => {
      return(chips.map((e,i)=>{return(e.value)}))
    }

    static formatUuid = (text) => {
      return text.substr(0, 4).replace(/[\r\n]/g, ' ').trim();
      //return text.substr(0, 3).replace(/[\r\n]/g, ' ').trim() + '...';
    }

    // Function to format description length
    static formatDescription = (text) => {
      return text.replace(/[\r\n]/g, ' ').trim();
    }

    //This should be the umbrella for all uuid-based caching methods for reports
    static isReportCached = async (uuid, cache_type) => {
      console.log("isReportCached called")
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        console.log("all keys", allKeys)
        if(allKeys.includes(cache_type)){
          var cache = JSON.parse(await AsyncStorage.getItem(cache_type))
          console.log("contents of cache: ",cache)
          return(cache.includes(uuid))
        }else{
          return(false)
        }
      }catch(error){
        console.log(error)
        console.log("isReportCached ERROR")
      }
      return(false)
    }

    static setReportCache = async (uuid, cache_type, keep=true) => {
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes(cache_type)){
          if(keep){
            var changedReports = JSON.parse(await AsyncStorage.getItem(cache_type))
            if(!(changedReports.includes(uuid))){
              changedReports.push(uuid)
              await AsyncStorage.setItem(cache_type,JSON.stringify(changedReports))
            }
          }else{
            var changedReports = JSON.parse(await AsyncStorage.getItem(cache_type))
            if(changedReports.includes(uuid)){
              changedReports = changedReports.filter(e => e != uuid)
              await AsyncStorage.setItem(cache_type,JSON.stringify(changedReports))
            }
          } 
        }else{
          if(keep){
            await AsyncStorage.setItem(cache_type,JSON.stringify([uuid]))
          }else{
            //Do nothing
          }
        }
      }catch(error){
        console.log("SET REPORT CHANGED ERROR")
        console.log(error)
      }
    }

    static convertTo12HourTime(time) {
      let timeArray = time.split(':')
      
      let hours = parseInt(timeArray[0])
      let minutes = parseInt(timeArray[1])

      let meridiem  = (hours >= 12) ? 'PM' : 'AM'

      hours = (hours % 12) || 12

      if (minutes < 10) {
        minutes = "0" + minutes
      }

      return hours + ':' + minutes + ' ' + meridiem
    }

    static timeConverter(t){
      var a = new Date(t)
      var today = new Date()
      var yesterday = new Date(Date.now() - 86400000)
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var year = a.getFullYear()
      var month = months[a.getMonth()]
      var date = a.getDate()
      var hour = a.getHours()
      var min = a.getMinutes()

      let time12Hour = this.convertTo12HourTime(hour + ':' + min)
      let day = ''

      if (a.setHours(0,0,0,0) == today.setHours(0,0,0,0))
          day = 'Today'
      else if (a.setHours(0,0,0,0) == yesterday.setHours(0,0,0,0))
          day = 'Yesterday'
      else if (year == today.getFullYear())
          day = month + ' ' + date
      else
          day = month + ' ' + date + ' ' + year

      return day + ',' + ' ' + time12Hour
    }

    static imageMode = {
      gallery: 0,
      camera: 1,
      cancel: 2
    }

static async  blobToBase64(blob) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob); // This will result in a Base64-encoded string
  });
};

static async forceJPEGConversion(base64Image) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Handle CORS for external images

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Convert and export image data as JPEG with proper compression
      const jpegDataUri = canvas.toDataURL('image/jpeg', 0.8); // 0.8 compression quality
      resolve(jpegDataUri);
    };

    img.onerror = reject;
    img.src = base64Image; // Assign the base64 image source
  });
}


    static async sendEmailNotification(details, message, type, organization, userId, images=[], isUrgent=false){
      try {
        // Get the current authenticated user
        const token = await this.getToken()
    
        // Prepare email data using the shared utility function
        const emailData = await sendEmailNotification(details, message, type, organization, userId, images, isUrgent);
    
        // Prepare request data
        const requestData = {
          body: emailData,
        };
    
        // Send email notification via Amplify API
        await this.post('Resty', '/items/emailNotification', requestData);
      } catch (e) {
        console.error('Error sending email notification:', e);
      }
    }

    static async getUploadedImageURLs(tryUploadImage, uploadImages, imagesToSend, setUploadedImages, setUploadStage, setUploadProgress) {
      let ui = uploadImages
      let fulfilledImageURLs;

      if(tryUploadImage == true){
        ui = await Util.uploadImages(imagesToSend, setUploadStage, setUploadProgress);
        setUploadedImages(() => ui)//Set this so we can include previously successful uploads in report
        fulfilledImageURLs = ui.filter((value,index)=>{return(value["status"] == "fulfilled")})
                            .map((value, index) => {return(value["value"])})
        if(fulfilledImageURLs.length < imagesToSend.length && !Util.uploadSuccessful(ui)){
          setUploadStage("Failed"); //Setting this to failed ensures the UI displays options
          return
        }      
      }else{
        fulfilledImageURLs = ui.filter((value,index)=>{return(value["status"] == "fulfilled")})
        .map((value, index) => {return(value["value"])})
      }

      let fullImageURLs = []
      if(fulfilledImageURLs.length > 0){
        const getImageURLPromises = fulfilledImageURLs.map(imageID => this.getImageURL(imageID, true))
        fullImageURLs = await Promise.all(getImageURLPromises)
      }

      return [ fullImageURLs, fulfilledImageURLs ]
    }

    static async pickImage(mode, setImageLoading, setImagesToSend) {
      if (mode == this.imageMode.cancel) {
        return;
      }

      // result.canceled never gets set on the web version.
      if (Platform.OS !== 'web') {
        setImageLoading(true)
      }

      // No permissions request is necessary for launching the image library
      //allowsEditing: true, //mutually exclusive with allowsMultipleSelection
      const imageOptions = {
        mediaTypes: 'images',
        allowsMultipleSelection: true,
      }

      let result;
      let permission;
      if (mode === this.imageMode.camera) {
        permission = await ImagePicker.requestCameraPermissionsAsync()

        // Open gallery if permission to use camera is not granted
        if (!permission.granted) {
          mode = this.imageMode.gallery
        }
      }

      result = mode === this.imageMode.camera ? await ImagePicker.launchCameraAsync(imageOptions) : await ImagePicker.launchImageLibraryAsync(imageOptions)

      // There will be a 2 second delay, but otherwise it will work.
      if (Platform.OS === 'web') {
        setImageLoading(true)  
      }

      let imageTooLarge = false;
      let notAnImage = false;

      if (!result.canceled) {
        let images = []

        for (let index in result.assets) {
          let image = result.assets[index]
          let actions = []

          // If the width can't be calculated, the image file is not valid. It is likely not an image.
          if (!image.width) {
            notAnImage = true
            continue
          }

          // Compress to low quality and resize to 800. This should make image size significantly smaller
          if (image.width > 800) {
            actions = [{ resize: { width: 800 }}]
          }

          // Create a manipulation context with the image URI
          const context = ImageManipulator.ImageManipulator.manipulate(image.uri);

          // Apply transformations
          context.resize({ width: 800 })  // Resize the image to a width of 800 pixels

          // Attempt to render the image
          const resizedImageRef = await context.renderAsync();
          const resizedImage = await resizedImageRef.saveAsync({base64: true, compress: .2, format: ImageManipulator.SaveFormat.JPEG})
        
          let sizeInBytes;
          let base64
          const response = await fetch(resizedImage.uri);
          const blob = await response.blob();
          sizeInBytes = blob.size;
          base64 = await this.blobToBase64(blob)


          if (sizeInBytes >= 6291456) { // 6MB limit
            imageTooLarge = true;
            continue;
          }

          // Add the resized image URI to the images array
          images.push(base64);
        }

        if (imageTooLarge) {
          alert("Some of your images are too large. Please resize and try again.")
        }
        if (notAnImage) {
          alert("Some of your files are not valid images. Please select image files only.")
        }

        setImagesToSend((i) => i.concat(images))
      }
      setImageLoading(false)
    }

    static uploadSuccessful (uploadProgress) {
      didSucceed = true
      Object.keys(uploadProgress).forEach((key ,index) => {
        if(!(uploadProgress[key] == "Finished")){
          didSucceed = false
        }
      })
      return didSucceed
    }

    static anyUploadsFailed (uploadProgress) {
      var didFail = false
      Object.keys(uploadProgress).forEach((key ,index) => {
        if((uploadProgress[key] == "Failed")){
          didFail = true
        }
      })
      return didFail
    }

    static async get(apiName, path, options) {

      if (!options) { options = {}}

      options = await this.addHeaders(options)
      const {body} = await API.get({apiName: apiName, path: path, options:options}).response
      return await body.json();
    }

    static async post(apiName, path, options) {
      options = await this.addHeaders(options)

      const {body} = await API.post({apiName: apiName, path: path, options:options}).response
      return await body.json();
    }

    static async patch(apiName, path, options) {
      options = await this.addHeaders(options)
      const {body} = await API.patch({apiName: apiName, path: path, options:options}).response
      return await body.json();
    }

    static async del(apiName, path, options) {
      options = await this.addHeaders(options)
      const {body} = await API.del({apiName: apiName, path: path, options:options}).response
      return await body.json();
    }

    static async addHeaders(options) {
      if (options && options["headers"]) { return options }
      
      const token = await this.getToken()
      options["headers"] = {
        Authorization: token
      }
      return options
    }
    static async getToken() {
      const user = await Auth.fetchAuthSession()
      return user.tokens.idToken.toString()
    }

    static async getImageClient() {

      const headers = {
        Authorization: await this.getToken(),
        "Content-Type": "application/octet-stream"
      }

      return generateClient({ headers: headers })

    }

    static async getImageURL(imageID, extendedExpiration=false) {
      // extendedExpiration defaults to 'false' to enforce a 5 minute
      // expiration for image URLs. If set to 'true', it will use the
      // max of 7 days.
      let expiration = extendedExpiration ? 7 * 24 * 60 * 60 : 60 * 5
      try{
        try{
          return this.get('Resty', '/items/getImageURL', {
            queryParams: {
              ImageID: imageID,
              Expiration: expiration
            }           
          }).then(result => {
            return(result);
          }).catch(error => {
            this.printAPIError(error)
          })
        } catch (e) {
          console.log(e.message);
        };
      } catch (e) {
        console.log(e.message);
      }
    }

    static setUploadProgressURI(uri, status, setUploadProgress) {
      setUploadProgress((before) => ({...before, [uri]: status}))
    }

    static async uploadImages (uris, setUploadStage, setUploadProgress) {
      setUploadStage("Uploading");
      const uploadPromises = uris.map(uri => this.uploadImage(uri, setUploadProgress));
      const uploadedImages = await Promise.allSettled(uploadPromises);
      setUploadStage("Finished");
      return uploadedImages;
    }

    // Calls uploadImage from API. Returns which images were successfully uploaded,
    // And the ID of those images. May fold this into the addComment API, but this can handle large images
    static async uploadImage(uri, setUploadProgress) {
      AWS.config.update({ region: process.env.TABLE_REGION });

      const session = await Auth.fetchAuthSession();
      const credentials = session.credentials;
      
      var S3 = new AWS.S3({
        apiVersion: 'latest', 
        signatureVersion: 'v4',       
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken, 
      }})
      // Generate a unique file name
      const fileName = uuidv4() + '.jpg';

      try {
        this.setUploadProgressURI(uri,"Uploading", setUploadProgress)
        const imageFile = uri.substring(uri.indexOf(",")+1);
        
        try{
            let base64string = imageFile;
            let base64buffer = buffer.from(base64string,"base64")
        
            // Define the S3 parameters
            const params = {
              Bucket: 'moneta-uploaded-images',
              Key: fileName,
              Body: base64buffer,
              ContentType: 'image/jpg',
            };
          
            return S3.upload(params).promise().then(result => {
            this.setUploadProgressURI(uri,"Finished", setUploadProgress)
            return(result.Key);
          }).catch(error => {
            this.setUploadProgressURI(uri,"Failed", setUploadProgress)
            return(result);
          })
        } catch (e) {
          console.log(e.message);
        };
      } catch (e) {
        console.log(e.message);
      }
    }

    /*
    static isReportChanged = async (uuid) => {
      console.log("isReportChanged called")
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes("changedReports")){
          var changedReports = JSON.parse(await AsyncStorage.getItem("changedReports"))
          console.log("changedReports: ",changedReports)
          return(changedReports.includes(uuid))
        }else{
          return(false)
        }
      }catch(error){
        console.log(error)
        console.log("isReportChanged ERROR")
      }
      return(false)
    }

    static isReportCommented = async (uuid) => {
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes("commentedReports")){
          var changedReports = JSON.parse(await AsyncStorage.getItem("commentedReports"))
          console.log("commentedReports: ",changedReports)
          return(changedReports.includes(uuid))
        }else{
          return(false)
        }
      }catch(error){
        console.log(error)
        console.log("isReportCommented ERROR")
      }
      return(false)
    }*/
}

export default Util