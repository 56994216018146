import React, { useState, useEffect } from "react"
import { ActivityIndicator, Pressable, View, StyleSheet, ScrollView, Platform, Dimensions} from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Feather } from '@expo/vector-icons';
import CustomText from "../components/CustomText";
import CustomTextElipses from "../components/CustomTextElipses";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Separator from '../components/Separator';
import Util from "../util/util.js"
import mobileStyles from "../style/mobileStyles.js";
import webStyles from "../style/webStyles.js";

import AsyncStorage from '@react-native-async-storage/async-storage';


const screenWidth = Dimensions.get('window').width
let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .7
}

const PreviousReport = (props) => {
    const { organization, admin } = props.route.params;

    const defaultOrganization = organization

    const [reports, setReports] = useState([]);

    const [reportsLoading, setReportsLoading] = useState(false)

    const hasComments = (element) => {
      return !(element.comments === 'No comments yet' || element.comments.length == 0)
    }
    
    async function getUserReports(){
        let routing = JSON.parse(await AsyncStorage.getItem("routing"))
        let routingData = Object.values(routing)

        try { 
          setReportsLoading(true)
          const requestData = {
            queryParams: {
                organization: organization,
                organizations: routingData.length ? organization + "," + routingData.toString() : organization
            }
          }
          try{ 
            const data = await Util.get('Resty', '/items/listUserObjects/:user', requestData)
            if(data.items.length > 0){
                setReports(data.items.sort((a, b) => b.timestamp - a.timestamp))
            }
            
            setReportsLoading(false)
          }
          catch(error){
            Util.printAPIError(error)
            setText(error.toString());
            setReportsLoading(false)
          }
        } catch (e) {
          console.log("failed2: ",e.message);
          setReportsLoading(false)
        }
      };

    const onScreenLoad = () => {
        getUserReports()
    }
    useEffect(() => {
        // write your code here, it's like componentWillMount
        onScreenLoad();
    }, [])

    return (
        <View style={Platform.OS === 'web' && screenWidth >=800 ? webStyles.container : mobileStyles.container}>
            {/* Global header  */}
            <Header organization={organization} admin={admin} />

            <View style={{ flex: 1, paddingTop: hp(1.7) }}>

                {/* Title  */}
                <View>
                <CustomText style={{ textAlign: 'center' }} font='medium' size={Platform.OS === 'web' ? 32 : 28} color={'#000'}>Previous Reports</CustomText>
        
                <View style={{ position: 'absolute', top: 10, right: 10}}>
                  <Pressable style={{cursor: 'pointer'}} onPress={(getUserReports)}>
                    <Feather name="refresh-cw" size={24 * scale} color={'#000'} />
                  </Pressable>
                </View>
                </View>
                <Separator />


                <View style={styles.orangeContainer}>
                    {/* Orange separators  */}
                    {/*<View style={{ position: 'absolute', left: wp(20.5), height: '100%', width: 3, backgroundColor: '#FFC451', marginVertical: hp(0.5) }} /> */}
                    {/*<View style={{ position: 'absolute', top: hp(8), height: 3, width: '100%', backgroundColor: '#FFC451', marginHorizontal: wp(14) }} />

                    {/* List header}
                    <View style={{ flexDirection: 'row', width: '100%', paddingHorizontal: wp(5) }}>
                        <CustomText font='medium' size={32} color='#000'>Id</CustomText>
                        <CustomText style={{ marginLeft: wp(10) }} font='medium' size={32} color='#000'>Description</CustomText>
                    </View>
                    */}

                    {/* Previous report mapping  */}
                    <ScrollView style={{paddingTop: hp(1), maxHeight: '70vh', width: '100%' }}>
                        {(reportsLoading) && <ActivityIndicator size="large"/>}
                        {
                            reports.length > 0 && reports.map((element, index) => {
                                return (
                                  <Pressable style={{ cursor: 'pointer'}} key={element.key} onPress={() => { element.index = reports.length-index; element.defaultOrganization = defaultOrganization; props.navigation.navigate('ReportDetails', element) }}>
                                  <View key={element.key} style={webStyles.reportContainer}>
                                    <View style={[webStyles.reportCheck, Platform.OS !== 'web' ? {height: hp(10)} : {}]}>
                                      {(element.isUrgent && !hasComments(element) && !element.isClosed) &&
                                        <Feather name={"alert-circle"} size={20 * scale} color={'red'} />
                                      }  
                                      {(hasComments(element) && !element.isClosed) &&
                                        <Feather name={"inbox"} size={20 * scale} color={'rgba(255, 196, 81, 1)'} />
                                      }         
                                      {(element.isClosed) &&
                                        <Feather name={"check"} size={20 * scale} color={'green'} />
                                      }          
                                    </View>   
                                    <View style={webStyles.reportInfoContainer}>
                                      <View  style={webStyles.reportInfo}>
                                        <CustomText style={webStyles.reportInfoItem} font='light' size={14}>Report ID: {Util.formatUuid(element.uuid)}</CustomText> 
                                        <CustomText style={webStyles.reportInfoItem} font='light' size={14}>Sent: {Util.timeConverter(element.timestamp)}</CustomText>
                                      </View> 
                                      <CustomTextElipses numberOfLines={1} ellipsizeMode="tail" style={webStyles.report} size={24 * scale}>{Util.formatDescription(element.content)}</CustomTextElipses>   
                                    </View>
                                  </View>
                                </Pressable> 
                                )
                            })
                        }
                        {(!reportsLoading && reports.length == 0) && 
                          <CustomText font='medium' size={24} style={[webStyles.report, {textAlign: 'center'}]}>
                            No reports submitted
                          </CustomText>}
                    </ScrollView>
                </View>
            </View>
            <Footer admin={admin} organization={defaultOrganization} />
        </View>
    )
}

const styles = StyleSheet.create({
  orangeContainer: {
    flex: 1,
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    paddingVertical: 10,
    paddingHorizontal: 14,
    marginTop: hp(1)
  }
})

export default PreviousReport;