import React, { useState, useEffect } from "react"
import { View, StyleSheet, TouchableWithoutFeedback, Pressable, TextInput, Text, Platform, Dimensions } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Wrap, Box } from 'react-native-flex-layout';
import CustomText from "./CustomText";


const screenWidth = Dimensions.get('window').width
let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .7
}
/******************************************************************************

ChipSection is useful for short text input. It makes for easy addition and deletion on mobile devices.

useState Values:
  Chips: List of text to use as chip titles
  setChips: set this variable, will update in parent as well

Callbacks:
  deleteChip()
    notifies parent of deletions
  addChip()
    notifies parent of addition
  
Permissions:
  canDelete
    if true, allows users to edit KPIs
  canAdd
    if true, allows users to add more KPIs. Depending on if validValues is supplied or not, chips will be either free-form text input, or pre-determined values.
  doSynchronize
    if true, notify parent of changes so that parent can synchronize how they wish

Info:
  styles
    buttonAdd
    buttonDelete
    Chip
    ChipAdd
    ChipText
    ChipSelected
  validValues
    Valid Values for restricted chip input




******************************************************************************/


const ChipSection = (props) => {
  const [ChipDeleteButton, setChipDeleteButton] = useState(null)
  const [ChipAddButton, setChipAddButton] = useState(null)
  const [InputChip, setInputChip] = useState(null)

  const [Chips, setChips] = useState([])

  const [ChipAddText, setChipAddText] = useState("")

  const [highlightedChips, setHighlightedChips] = useState([])


  //Run on start
  useEffect(() =>{
  }, [])

  //Runs when Chips changes
  useEffect(() => {
    console.log("highlightedChips changed")
    console.log(props.canDelete)
    console.log(highlightedChips)
    console.log(highlightedChips.length)
    if(props.canDelete && highlightedChips.length > 0){
      console.log("adding delete button")
        setChipDeleteButton(() =>
          <TouchableWithoutFeedback onPress={() => _deleteChips()}>
            <View style={props.styles.buttonDelete}>
              <CustomText font='medium' size={15} color={'#FFFFFF'}>Delete Selected Tags</CustomText>
            </View>
          </TouchableWithoutFeedback>
        )
    }else{
      console.log("not adding delete button")
      setChipDeleteButton(() => null)
    }
    },[highlightedChips])

  //Runs when ChipAddText changes
  useEffect(() => {
    if(props.canAdd && ChipAddText.length > 0){
      setChipAddButton(()=>    
        <TouchableWithoutFeedback onPress={() => addChip()}>
          <View style={props.styles.buttonAdd}>
            <CustomText font='medium' size={15} color={'#FFFFFF'}>Add Tag</CustomText>
          </View>
        </TouchableWithoutFeedback>
      )
    }else{
      setChipAddButton(() => null)
    }
  },[ChipAddText])



  useEffect(() =>{
    console.log("validValues or Chips changed")
    if(props.canAdd){
      var cut = []
      const currentValues = props.Chips
      console.log("current values: " + currentValues)
      console.log("validValues: " + props.validValues)
      if(props.validValues){
        props.validValues.forEach((value, index) => {
          if(currentValues.indexOf(value) == -1){
            cut.push(value)
          }
        })
      }
      
      console.log("cut values: " + cut)

      if(cut && cut.length > 0){
        setInputChip(() => cut.map((value,index) => 
        <Pressable key={index} onPress = {() => handleRestrictedInputAdd(value)}>
          <Box style = {props.styles.ChipAdd}>
            <Text style={props.styles.ChipText}>{"+" + value}</Text>
          </Box>
        </Pressable>
        ))
      }else if(!props.validValues){
        setInputChip(() => (
        <Pressable>
          <Box style = {props.styles.ChipAdd}>
            <TextInput value={ChipAddText} onChangeText={value => setChipAddText(() => value)}
              placeholder="+ Add New Tag" placeholderTextColor={'rgba(0,0,0,0.35)'} style={props.styles.ChipText} />
          </Box>
        </Pressable>))
      }else{
        setInputChip(() => null)
      }
    }
  }, [props.validValues, props.Chips, ChipAddText, props.doSynchronize])

  //INTERNAL METHOD
  //Allows user to highlight chips for deletion
  const toggleChip = (element) => {
    console.log("toggleChip ran: ", element)
    console.log(highlightedChips)
    if(props.canDelete){
      if(highlightedChips.includes(element)){
        var copy = [...highlightedChips]
        copy = copy.filter(e => e != element)
        console.log("new value for highlightedChips: ", copy)
        setHighlightedChips(() => copy)
      }else{
        var copy = [...highlightedChips]
        copy.push(element)
        console.log("new value for highlightedChips: ", copy)
        setHighlightedChips(() => copy)
      }
    }
  }

  //EXTERNAL METHOD
  //Add a chip to the internal state and update parent's state - Use restricted input mode
  const handleRestrictedInputAdd = (text) => {
    if(props.canAdd){
      if(text=="" || text.length <= 1){
        return(null)
      }

      var chips = [...props.Chips]

      chips.push(text)
      if(props?.sortChips){
        chips = chips.sort()
      }
      

      props.setChips(() => chips)//update state of Chips object

      //notify parent
      if(props.doSynchronize){
        props.addChip(text); //Call parent implementation for adding Chip
      }
    }
  }

  //EXTERNAL METHOD
  //Adds a chip to the internal state and update's parent's state.
  const addChip = async () => {
    if(props.canAdd){

      let text = ChipAddText
      setChipAddText(()=>"")//Immediately remove to avoid duplication
      setChipAddButton(()=>null) //Immediately remove for same reason

      // Remove leading and trailing spaces. Don't add blank tags.
      text = text.trim()
      var chips = props.Chips
      if(text=="" || text.length <= 1 || chips.includes(text)){
        return(null)
      }

      //If text is not empty, add new chip
      chips.push(text)
      if(props?.sortChips){
        chips = chips.sort()
      }

      props.setChips(chips)//update state of Chips object

      //notify parent
      if(props.doSynchronize){
        props.addChip(text); //Call parent implementation for adding Chip
      }
    }
  }

  //EXTERNAL METHOD
  /*
  _deleteChips

  if deletion privaledges are granted, update child and parent state by deleting highlighted chips

  */
  const _deleteChips = () => {
    if(props.canDelete){
      const resultantList = props.Chips.filter(e => !(highlightedChips.includes(e)))
      const deletedList = props.Chips.filter(e => (highlightedChips.includes(e)))

      props.setChips(resultantList)
      setHighlightedChips(() => [])
      //notify of exact change
      if(props.doSynchronize){
        props.deleteChips(deletedList)
      }
    }
  };
  
  if(Platform.OS === 'web'){
    return(
      <View style={componentStyles.container}>
        <Wrap shouldWrapChildren="true" spacing={5}>
            {/* Chip Chips  */}
            {
                props.Chips.length == 0 ? null : props.Chips.map((element, index) =>{
                    return(
                        <TouchableWithoutFeedback key={index} onPress={() => {
                          if(!props.maxSelect || (props.maxSelect >= highlightedChips.length && highlightedChips.includes(element)) || (props.maxSelect > highlightedChips.length && !highlightedChips.includes(element))){
                            toggleChip(element)
                          }                          
                        }}>
                            <Box style = {highlightedChips.includes(element) ? props.styles.ChipSelected : props.styles.Chip}>
                                <CustomText style = {props.styles.ChipText} font='medium' size={15} color={'#000'}>{element}</CustomText>
                            </Box>
                        </TouchableWithoutFeedback>
                    )
                })
            }
            {props.canAdd && InputChip}
        </Wrap>
        {props.canDelete && ChipDeleteButton}
        {props.canAdd && ChipAddButton}

    </View>
    )
  }else{
    return(
      <View style={componentStyles.container}>
        <Wrap shouldWrapChildren="true" spacing={5}>
            {/* Chip Chips  */}
            {
                props.Chips.length == 0 ? null : props.Chips.map((element, index) =>{
                    return(
                        <TouchableWithoutFeedback key={index}  onPress={() => {
                          if(!props.maxSelect || (props.maxSelect >= highlightedChips.length && highlightedChips.includes(element)) || (props.maxSelect > highlightedChips.length && !highlightedChips.includes(element))){
                            toggleChip(element)
                          }                          
                        }}>
                            <Box style = {highlightedChips.includes(element) ? props.styles.ChipSelected : props.styles.Chip}>
                                <CustomText style = {props.styles.ChipText} font='medium' size={15} color={'#000'}>{element}</CustomText>
                            </Box>
                        </TouchableWithoutFeedback>
                    )
                })
            }
            {props.canAdd && InputChip}
        </Wrap>
        {props.canDelete && ChipDeleteButton}
        {props.canAdd && ChipAddButton}
    </View>
    )
  }
};

const componentStyles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      flex: 1,
      flexBasis: "content"
    }
  });

export default ChipSection;