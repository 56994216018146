import React from "react";
import { View, Image, Dimensions, Platform, Pressable } from "react-native";
import { Feather } from '@expo/vector-icons';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useNavigation } from '@react-navigation/native';
import * as Auth from 'aws-amplify/auth';

import CustomText from "../components/CustomText";

const { width } = Dimensions.get('screen');
let scale = 1

// Adjust header elements for viewing the web portal on smaller devices
if (width < 800 ) {
    scale = .55
  }

  
const Header = (props) => {
    const navigation = useNavigation();
    return (
        <View style={{ paddingHorizontal: wp(2.7), paddingVertical: hp(1.8), paddingTop: hp(5), backgroundColor: '#000', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <View style={{flexDirection: 'column'}}>
                <Pressable style={[styles.button, {marginLeft: 5}]} onPress={() => { if(props.type === 'pop'){ navigation.pop()} else{ Auth.signOut(); navigation.navigate('Login') }}}>
                    <Feather name={props.type === 'pop' ? 'corner-up-left' : 'log-out'} size={40 * scale} color={'#FFF'} />
                </Pressable>
                <CustomText size={12} style={{color: '#fff', textAlign: 'center'}}>{props.type === 'pop' ? 'Back': 'Log Out'}</CustomText>
            </View>
            <Image source={require('../assets/images/logo-white.png')} style={[Platform.OS === 'web' ? {} : {marginLeft:10}, { maxWidth: 250, width: wp(50), height: 54}]}/>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            {props.hasOwnProperty("organization") && <View style={{flexDirection: 'column'}}>
            <Pressable style={styles.button} onPress={() => {navigation.navigate('Settings', props)}}>
                <Feather name='user' size={40 * scale} color={'#FFF'} /> 
            </Pressable> 
            <CustomText size={12} style={{color: '#fff', textAlign: 'center'}}>Profile</CustomText>
            </View>}
            {props.hasOwnProperty("organization") && <View style={{flexDirection: 'column'}}>
            <Pressable style={[styles.button, {}]} onPress={() => {navigation.navigate('SubmitBug', props)}}>
                <Feather name='help-circle' size={40 * scale} color={'#FFF'} />
            </Pressable> 
            <CustomText size={12} style={{color: '#fff', textAlign: 'center'}}>Help</CustomText>
            </View>}
            </View>
        </View>
    )    
}

const styles = {
    button: {
        cursor: 'pointer',
        paddingLeft: 8,
        paddingRight: 8
    }
}

export default Header;