import * as Linking from 'expo-linking';

// Generate a URL prefix from your app's custom scheme defined in app.json
const prefix = Linking.createURL('/');

export const linkingObject = {
    prefixes: [prefix, 'https://monetareport.net', 'monetareport.net'],
    config: {
        screens: {
            Login: 'login',
            Home: 'home',
            PreviousReport: 'previous-report',
            ReportDetails: 'report-details/:id',
            SubmitReport: 'submit-report',
            SubmitBug: 'submit-bug',
            Settings: 'settings',
            PrivacyPolicy: 'privacy-policy',
            AdminHome: 'admin-home',
            AdminSettings: 'admin-settings',
            AdminReportDetails: 'admin-report-details/:id',
            UserManagement: 'user-management',
            ConfirmSignUp: 'confirm-signup',
            ForgotPassword: 'forgot-password',
            ForgotPasswordInternal: 'forgot-password-internal',
            ResetPassword: 'reset-password',
            AdminFilterHome: 'admin-filter-home',
            HomePremium: 'home-premium',
            AnalyticsScreen: 'analytics',
            AdminOrganizationDashboard: 'admin-organization-dashboard',
        },
    },
};