import React, { useState } from "react"
import { View, Pressable, Platform, StyleSheet, ScrollView } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Separator from "../components/Separator";
import Textbox from "../components/Textbox";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";

const AdminSettings = (props) => {

    // Defining local state values
    const { organization } = props.route.params;
    const [email, setEmail] = useState('example@mail.com');
    const [company, setCompany] = useState('The Company');
    const [reports, setReports] = useState('001');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    return (
        <ScrollView style={Platform.OS === 'web' ? webStyles.container : mobileStyles.container} contentContainerStyle={{ flexGrow: 1, paddingBottom: hp(3.7) }}>
            {/* Global header  */}
            <Header organization={organization} admin={true} />

            {/* Title  */}
            <View style={{ paddingHorizontal: wp(5) }}>
                <CustomText style={{ textAlign: 'center' }} font='medium' size={32} color={'#000'}>Jerry Smith</CustomText>
                <Separator />
            </View>

            <View style={{ flex: 1, justifyContent: 'space-between', paddingHorizontal: wp(6), paddingTop: hp(1.7) }}>
                {/* Email textbox  */}
                <View>
                    <CustomText style={{ textAlign: 'center' }} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Email</CustomText>
                    <Textbox value={email} onChangeText={(text) => { setEmail(text) }} />
                </View>
                {/* Company textbox  */}
                <View>
                    <CustomText style={{ textAlign: 'center' }} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Company</CustomText>
                    <Textbox value={company} onChangeText={(text) => { setCompany(text) }} />
                </View>
                {/* Report number textbox  */}
                <View>
                    <CustomText style={{ textAlign: 'center' }} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Number of reports</CustomText>
                    <Textbox keyboardType={'numeric'} value={reports} onChangeText={(text) => { setReports(text) }} />
                </View>
                {/* Change password header */}
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: hp(3) }}>
                    <View style={{ height: 1, flex: 1, backgroundColor: '#000' }} />
                    <CustomText style={{ marginHorizontal: 12 }} font='bold' size={20} color='#000'>Change Password</CustomText>
                    <View style={{ height: 1, flex: 1, backgroundColor: '#000' }} />
                </View>
                {/* Password changing textboxes  */}
                <Textbox value={currentPassword} placeholder='Current password' onChangeText={(text) => { setCurrentPassword(text) }} />
                <Textbox value={newPassword} placeholder='New password' onChangeText={(text) => { setNewPassword(text) }} />
                {/* Change password button  */}
                <Pressable onPress={() => { }}>
                    <View style={styles.button}>
                        <CustomText font='medium' size={15} color={'#000'}>Change password</CustomText>
                    </View>
                </Pressable>
            </View>
            <Footer admin={true} />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(4),
        width: '70%',
        alignSelf: 'center'
    }
})

export default AdminSettings;