import React, { useState, useEffect } from "react"
import Constants from 'expo-constants';
import { ActivityIndicator, Pressable, StyleSheet, Image, TextInput, View, TouchableWithoutFeedback, Platform, Keyboard, Dimensions } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from '../components/CustomText';
import { fonts } from "../style/fonts";
import { Feather } from '@expo/vector-icons';
import Toast from 'react-native-root-toast';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import * as Notifications from 'expo-notifications';
import alert from '../components/alert'
import Util from "../util/util.js"
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import AsyncStorage from '@react-native-async-storage/async-storage';


const screenWidth = Dimensions.get('window').width;

const Login = (props) => {

    const tryAutoLogin = props ? props.route.params : false
    
    if (Platform.OS === 'web') {
        console.log = function () {};
    }
    
    // Defining local state values
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [icon, setIcon] = useState("eye-off")
    const [hidePassword, setHidePassword] = useState(true)

    const [userFontSize, setUserFontSize] = useState(24);

    const [isBusy, setIsBusy] = useState(false)


    const changeIcon = () => {
        icon !== "eye-off"
        ? (setIcon("eye-off"), setHidePassword(true))
        : (setIcon("eye"), setHidePassword(false))
    }

    const atob = (base64) => {
        const buffer = Buffer.from(base64, 'base64');
        return buffer.toString('binary');
    };

    const btoa = (text) => {
        const buffer = Buffer.from(text, 'binary');
        return buffer.toString('base64');
    };

    async function storePushNotificationKey(){
        if (Platform.OS != 'web') {
            // Check for notifications permissions. If not set, request permission.
            permissions = (await Notifications.getPermissionsAsync()).status
            if (permissions !== 'granted') {
                permissions = (await Notifications.requestPermissionsAsync()).status
            }

            // Permission not granted, return without storing token
            if (permissions !== 'granted') {
                return
            }

            // If we got new push notification token, store in database
            // Sending in the projectId because the current version of expo-notifications is looking in the wrong place
            token = (await Notifications.getExpoPushTokenAsync({'projectId': Constants.expoConfig?.extra?.eas?.projectId})).data;
            
            const requestData = {
                body: {
                pnTok: token
                }
            }
            try{
                await Util.post('Resty', '/items/addPushNotificationToken/', requestData)
            }
            catch(error){
                Util.printAPIError(error)
            }
        }   
    }
    
    let isAdmin;
    let hasUserManagement;
    let organization;
    async function getUserRoles(){
        const response = await Util.get('Resty', '/items/getUserRoles')

        if ( response.data) {
            organization = response.data.organization ?? organization
            const organizationAccess = response.data.organization_access ?? []
            isAdmin = response.data.Roles.includes("Analyst")
            hasUserManagement = response.data.Roles.includes("UserAdmin")

            // Organization Access
            await AsyncStorage.setItem("organizationAccess", organizationAccess.toString())

            await setOrganizationSettings()

        } else if (email !== '') {
            throw new Error('User is not yet registered to an organization')
        } else {
            // If the user hasn't typed anything, don't throw an error. This could
            // happen if the automatic login failed due to being unable to get the user's roles.
            throw new Error (undefined)
        }
    }

    async function setOrganizationSettings() {
        const request = {queryParams: {'organization': organization}}

        const organizationData = await Util.get('Resty', '/items/organization', request)

        const routing = organizationData['data']['routing'] ? organizationData['data']['routing'] : []
    
        await AsyncStorage.setItem("routing", JSON.stringify(routing))
        await AsyncStorage.setItem("organization", organization)
    }

    async function executeLogin() {
        try {         
            await getUserRoles();

            Toast.show('✅ Successfully logged in!', {
                backgroundColor: "#259e23",
                duration: 2500,
                textColor: "#fff",
            });
        } catch (e) {
            // If the user needs to reset their password on first login, error will be undefined.
            // In this case we don't want to display the error message, but will redirect the user instead.
            if (e.message !== 'Unauthenticated access is not supported for this identity pool.') {
              alert('❌ ' + e.message)
            }
            setIsBusy(false)
            return
        }
        
        storePushNotificationKey();
        props.navigation.navigate("SubmitReport", {organization: organization, admin: isAdmin})
        setIsBusy(false)
    }

    useEffect(() => {
        // Auto login on first load, or when auto login param is set
        autoLogin()
    }, [tryAutoLogin])

    useEffect(() => {
        setUserFontSize(Util.calculateFontSize(email, 41, userFontSize))
    }, [email])

    function autoLogin() {
        fetchAuthSession().then(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (_currentAuthenticatedUser) => {
                setIsBusy(true)
                executeLogin();
            },
            (error) => {
                /* Not logged in, do nothing */
            }
            );
    }

    async function isAlreadyAuthenticated() {
        try {
            await fetchAuthSession()
            return true
        } catch (e) {
            return false
        }
    }

    async function executeSignIn() {   
        try {
            try{await signOut()}catch(e){}
            if(await isAlreadyAuthenticated()) {
                return
            }

            setIsBusy(true)
            await signIn({
                username: email,
                password: password,
                options: {
                    authFlowType: 'USER_PASSWORD_AUTH',
                },
            }).then(user => {
                if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'){
                    props.navigation.navigate('ResetPassword', {initialLogin: true, user: user, authenticatedEmail: email, oldPassword: password})
                }
            });

            executeLogin();

        } catch (error) {
            setIsBusy(false)
            let message = error.toString()
            if (!message.includes('Pending sign-in')) {
              // Use alert instead of toast to make sure the user sees it.
              alert("Error", '❌ Error Signing In.\n' + message)
            }
        }
    }

    return (
        <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
        <View style={[Platform.OS === 'web' && screenWidth >= 800 ? webStyles.container : mobileStyles.container, {alignItems: 'center', paddingHorizontal: wp(5.6), paddingVertical: hp(12.3) }]}>
            
        { /* TWF at top is so pressing anywhere on the screen will dismiss the keyboard */}

            {/* Header  */}
            <Image source={require('../assets/images/logo-black.png')} style={{ width: 281, height: 78 }} />
            <View style={{ height: 1, width: '100%', backgroundColor: '#000', marginTop: hp(1.8) }} />
            <CustomText style={{ marginTop: hp(1) }} font='semiBold' size={40} color='#000'>Login</CustomText>
 

            {/* Email textbox  */}
            <View style={styles.textboxContainer}>
                <View style={styles.textboxIconContainer}>
                    <Feather name="mail" size={24} color={'#000'} />
                </View>
                <TextInput 
                  autoCapitalize={'none'}
                  autoCorrect={false}
                  textContentType={'username'} 
                  allowFontScaling={true} 
                  value={email} 
                  onChangeText={(text) => { setEmail(text.trim()) }} 
                  placeholder="Email" 
                  placeholderTextColor={'rgba(0,0,0,0.35)'} 
                  style={[styles.textboxText, {fontSize: userFontSize}]} />
            </View>

            {/* Password textbox  */}
            <View style={styles.textboxContainer}>
                <View style={styles.textboxIconContainer}>
                    <Feather name="lock" size={24} color={'#000'} />
                </View>
                <TextInput 
                  autoCapitalize={'none'}
                  autoCorrect={false}
                  textContentType={'password'} 
                  secureTextEntry={hidePassword} 
                  value={password} 
                  onChangeText={(text) => { setPassword(text) }} 
                  placeholder="Password" 
                  placeholderTextColor={'rgba(0,0,0,0.35)'} 
                  style={[styles.textboxText, {fontSize: 24}]} />
                <View style={styles.textboxIconContainerEye}>
                    <Feather name={icon} size={24} onPress={() => changeIcon()} />
                </View>
            </View>

            {/* Login button  */}
            <Pressable onPress={executeSignIn}>
                <View style={styles.button}>
                    <CustomText  font='medium' size={32} color={'#000'}>Log in </CustomText>
                    {isBusy &&  <ActivityIndicator  size="small"/>}
                    {!isBusy && <Feather name='chevron-right' size={50} color={'#000'} />}
                </View>
            </Pressable>

            {/* Forgot password button */}
            <Pressable onPress={() => { props.navigation.navigate('ForgotPassword') }}>
                <View style={{cursor: 'pointer'}}>
                    <CustomText style={{ textDecorationLine: 'underline', marginTop: hp(3.9) }} font='thin' size={24} color='#000'>Forgot password?</CustomText>
                </View>
            </Pressable>

            
            {/* Privacy Policy */}
            <Pressable onPress={() => { props.navigation.navigate('PrivacyPolicy') }}>
                <View style={{cursor: 'pointer'}}>
                    <CustomText style={{ textDecorationLine: 'underline', marginTop: hp(3.9) }} font='thin' size={24} color='#000'>Privacy Policy</CustomText>
                </View>
            </Pressable>
        </View>
        </TouchableWithoutFeedback>
    )
}

const styles = StyleSheet.create({
    textboxContainer: {
        flexDirection: 'row',
        backgroundColor: 'rgba(255, 196, 81, 0.3)',
        borderRadius: 12,
        width: Platform.OS === 'web' && screenWidth >= 800 ? wp(40) : wp(80),
        height: hp(6.8),
        marginTop: hp(3.8),
    },
    textboxIconContainer: {
        backgroundColor: '#FFC451',
        borderRadius: 12,
        width: '15.6%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textboxIconContainerEye: {
        backgroundColor: '#FFC451',
        borderRadius: 12,
        width: Platform.OS === 'web' && screenWidth >= 800 ? wp(4) : wp(12),
        justifyContent: 'center',
        alignItems: 'center'
    },
    textboxText: {
        flex: 1,
        fontFamily: fonts.POPPINS.medium,
        color: '#000',
        marginLeft: 8,
        marginBottom: -4,
        width: '100%'
    }, 
    button: {
        cursor: 'pointer',
        flexDirection: 'row',
        backgroundColor: '#FFC451',
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        width: Platform.OS === 'web' && screenWidth >= 800 ? wp(40) : wp(80),
        height: hp(6.8),
        marginTop: hp(5.5)
    }
})

export default Login;