import React, { useState, useEffect, useRef } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import Home from "../screens/Home";
import Login from "../screens/Login";
import PreviousReport from "../screens/PreviousReport";
import ReportDetails from "../screens/ReportDetails";
import SubmitReport from "../screens/SubmitReport";
import SubmitBug from "../screens/SubmitBug";
import Settings from "../screens/Settings";
import AdminHome from "../screens/AdminHome";
import AdminSettings from "../screens/AdminSettings";
import AdminReportDetails from "../screens/AdminReportDetails";
import UserManagement from "../screens/UserManagement";
import ConfirmSignUp from "../screens/ConfirmSignUp";
import ForgotPassword from "../screens/ForgotPassword";
import ForgotPasswordInternal from "../screens/ForgotPasswordInternal";
import ResetPassword from "../screens/ResetPassword";
import AdminFilterHome from "../screens/AdminFilterHome";
import AdminOrganizationDashboard from "../screens/AdminOrganizationDashboard";

//New Screens for premium
import HomePremium from "../screens/HomePremium";
import AnalyticsScreen from "../screens/AnalyticsScreen";
import PrivacyPolicy from "../screens/PrivacyPolicy";

import { linkingObject } from './LinkingObject.js';


Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const Stack = createStackNavigator();

const RootStack = () => {

  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  async function registerForPushNotificationsAsync() {
    let token;

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      // Maybe have to refresh every time - I don't know tbh
      /*
      if(existingStatus === 'granted'){
          console.log("User already granted access") // Do i have to refresh every so often??
          return;
      }
      */
      if (existingStatus !== 'granted') {
        console.log("Asking for push notification permission")
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return;
      }
    } else {
      console.log('Must use physical device for Push Notifications');
    }
    // Push notification is stored after the users logs in
  }

  useEffect(() => {
    // Orignally done in login.js
    registerForPushNotificationsAsync()

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response.notification.request.content.body);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [])

  return (
    <NavigationContainer linking={linkingObject}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="PreviousReport" component={PreviousReport} />
        <Stack.Screen name="ReportDetails" component={ReportDetails} />
        <Stack.Screen name="SubmitReport" component={SubmitReport} />
        <Stack.Screen name="SubmitBug" component={SubmitBug} />
        <Stack.Screen name="Settings" component={Settings} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen name="AdminHome" component={AdminHome} />
        <Stack.Screen name="AdminSettings" component={AdminSettings} />
        <Stack.Screen name="AdminReportDetails" component={AdminReportDetails} />
        <Stack.Screen name="UserManagement" component={UserManagement} />
        <Stack.Screen name="ConfirmSignUp" component={ConfirmSignUp} />
        <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
        <Stack.Screen name="ForgotPasswordInternal" component={ForgotPasswordInternal} />
        <Stack.Screen name="ResetPassword" component={ResetPassword} />
        <Stack.Screen name="AdminFilterHome" component={AdminFilterHome} />
        <Stack.Screen name="HomePremium" component={HomePremium} />
        <Stack.Screen name="AnalyticsScreen" component={AnalyticsScreen} />
        <Stack.Screen name="AdminOrganizationDashboard" component={AdminOrganizationDashboard} />

      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default RootStack;