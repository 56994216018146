import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, StyleSheet, TouchableWithoutFeedback, Platform, Keyboard, Dimensions } from 'react-native';
import * as Auth from 'aws-amplify/auth';

import AppTextInput from '../components/AppTextInput';
import AppButton from '../components/AppButton';
import Toast from 'react-native-root-toast';
import alert from '../components/alert'

import Header from "../components/Header.js"
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import Util from '../util/util.js';

const screenWidth = Dimensions.get('window').width

const ForgotPassword = (props) => {
  const [username, setUsername] = useState('');
  const [userFontSize, setUserFontSize] = useState(24);

  useEffect(() => {
    setUserFontSize(Util.calculateFontSize(username, 48, userFontSize))
}, [username])

  async function ForgotPassword() {
    try {
      await Auth.resetPassword({username: username});

      Toast.show('✅ Verification Code Sent!', {
        backgroundColor: "#259e23",
        duration: 5000,
        //position: 50,
        textColor: "#fff",
      });
      props.navigation.navigate('ResetPassword')
    } catch (error) {
      console.log(
        '❌ Verification code does not match. Please enter a valid verification code.',
        error.toString()
      );
      
      alert("Error", "❌" + error.toString())
    }
  }
  return (
    <View style={[styles.safeAreaContainer, Platform.OS === 'web' && screenWidth < 800 ? {width: '100%'} : {} ]}>
      <Header  type='pop'/>
      <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
      <View style={styles.container}>
        <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.title}>Enter the email needing a password reset</Text>
        <AppTextInput
          value={username}
          onChangeText={text => setUsername(text)}
          leftIcon="account"
          placeholder="Enter email"
          autoCapitalize="none"
          keyboardType="email-address"
          textContentType="emailAddress"
          style={{fontSize: userFontSize, width: '100%'}}
        />
        <AppButton title="Send Password Reset Code" onPress={ForgotPassword} />
        <View style={styles.footerButtonContainer}>
          <Pressable onPress={() => props.navigation.navigate('Login')}>
            <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.forgotPasswordButtonText}>
              No longer need a reset? Sign In
            </Text>
          </Pressable>
        </View>
      </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white',
      width: screenWidth >= 800 ? "70%" : "100%",
      alignSelf: 'center'
    },
    container: {
      flex: 1,
      alignItems: 'center',
      marginTop: 15,
      marginLeft: 10
    },
    title: {
      fontSize: 20,
      color: 'black',
      fontWeight: '500',
      marginVertical: 15
    },
    footerButtonContainer: {
      marginVertical: 15,
      justifyContent: 'center',
      alignItems: 'center'
    }, 
    forgotPasswordButtonText: {
        color: '#FFC451',
        fontSize: 18,
        fontWeight: '600'
      }
  });

  export default ForgotPassword;