import React, { useState, useEffect } from 'react'
import { View, Dimensions, ScrollView, StyleSheet, TouchableWithoutFeedback, TextInput, Platform, Keyboard, Pressable} from 'react-native'
import { DataTable } from 'react-native-paper';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen"
import * as Auth from 'aws-amplify/auth'
import { SafeAreaView } from 'react-native-safe-area-context'
import AppTextInput from '../components/AppTextInput.js'
import AppButton from '../components/AppButton.js'
import Toast from 'react-native-root-toast'
import alert from '../components/alert.js'
import CustomText from "../components/CustomText"
import { Wrap, Box } from 'react-native-flex-layout'
import DropDownPicker from 'react-native-dropdown-picker'
import * as DocumentPicker from 'expo-document-picker';
import { readString } from 'react-native-csv'
import { Feather } from '@expo/vector-icons';
import { fonts } from "../style/fonts";

import webStyles from "../style/webStyles.js"
import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import DeleteUserButton from "../components/DeleteUserButton.js"
import UpdateUserButton from '../components/UpdateUserButton.js';
import UserRoles from '../components/UserRoles.js'
import ResetUserPassword from '../components/ResetUserPassword.js';

import Util from "../util/util.js"
import { Discovery } from 'aws-sdk';

const screenWidth = Dimensions.get('window').width
const modes = {
  create: "create",
  edit: "edit",
  delete: "delete"
}

let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .7
}
const UserManagement = (props) => {

  const [showUsers, setShowUsers] = useState(false);
  const [editUsers, setEditUsers] = useState({});

  const [searchText, setSearchText] = useState('');

  const [existingUsers, setExistingUsers] = useState({})
  const [filteredUsers, setFilteredUsers] = useState({})
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [deleteConfirmed, setConfirmDelete] = useState(false)
  const [mode, setMode] = useState(modes.create)

  const [errors, setErrors] = useState({})
  const [isFormValid, setIsFormValid] = useState(false)
  const [displayErrors, setDisplayErrors] = useState(false)

  const { organization, admin } = props.route.params

  const defaultRoles = [{'label': 'Reporter', 'value': 'Reporter'}, {'label': 'Admin', 'value': 'Analyst'}]
  const optionalRoles = [
    {'label': 'Keep current role', 'value': null}, 
    {'label': 'Reporter', 'value': 'Reporter'}, 
    {'label': 'Admin', 'value': 'Analyst'}]
  const useDefaultValue = 'Reporter'
  const useOptionalValue = null
  const [rolesOpen, setRolesOpen] = useState(false)
  const [rolesFilterValue, setRolesFilterValue] = useState(useDefaultValue)
  const [rolesFilter, setRolesFilter] = useState(defaultRoles)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)

  const [uploadErrors, setUploadErrors] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(false);

  const validRoles = {'reporter': 'Reporter', 'analyst': 'Analyst'}

  const [roleSearchOpen, setRoleSearchOpen] = useState(false)
  const [roleSearchValue, setRoleSearchValue] = useState([])
  const [roleSearchFilter, setRoleSearchFilter] = useState(defaultRoles)

  const [statusSearchOpen, setStatusSearchOpen] = useState(false)
  const [statusSearchValue, setStatusSearchValue] = useState([])
  const [statusSearchFilter, setStatusSearchFilter] = useState([
    {'label': 'Active', 'value': 'Active'}, 
    {'label': 'Pending', 'value': 'Pending'},
    {'label': 'Inactive', 'value': 'Inactive'},
  ])

  useEffect(() => {
    Auth.fetchUserAttributes().then((user) => { setAuthenticatedUser(user) })
    getUsers();
  }, [])

  useEffect(() => { 
    // Validate form if username, password or mode is changed
    validateForm()
  }, [username, password, mode, authenticatedUser])

  useEffect(() => { 
    // Filter users to match search term. Update if existingUsers has changed. 
    setShowUsers(mode !== modes.create || searchText.length > 1)
    if (searchText && searchText.length > 1 || roleSearchValue.length || statusSearchValue.length) {
      filterUsers()
    } else if (mode !== modes.create ) {
      setFilteredUsers(existingUsers)
    
    } else if (mode === modes.create) {
      setFilteredUsers([])
    }
    
  }, [searchText, existingUsers, roleSearchValue, statusSearchValue, mode])

  const resetSearch = async() => {
    setSearchText('')
  }

  const enterEmail = (text) => {
    setUsername(text.toLowerCase())
    setEmail(text.toLowerCase())
    setSearchText(text)
  }
  const validateForm = () => { 
    /*
    * Form validation
    */
    let errors = {}

    // Require email
    if (!username.trim()) { 
        errors.email = 'Email is required.'
    } 
    
    // Password must be 8 characters (if creating new user)
    if ((password.trim().length < 8  && mode === modes.create) || (password.trim().length > 0 && password.trim().length < 8  && mode === modes.edit)) {
      errors.password = 'Password must be at least 8 characters.' 
    }

    // Don't allow adding a user that already exists
    if (mode == modes.create && username.trim().toLowerCase() in existingUsers) {
      errors.email = 'User already exists.'
    }

    // Don't allow adding a user that already exists
    if (mode == modes.edit && !(username.trim().toLowerCase() in existingUsers)) {
      errors.email = 'User does not exist.'
    }

    // Don't allow changing own user
    if (authenticatedUser && authenticatedUser.email.toLowerCase() == username.toLowerCase()) {
      errors.email = 'Must not be your own email.'
    }

    // Set the errors and update form validity 
    setErrors(errors)
    setIsFormValid(Object.keys(errors).length === 0) 
  }; 

  const filterUsers = () => {
    /*
    * Filter existingUsers to those that match the search term
    */
    setFilteredUsers({})
    const searchRegex = new RegExp(`\\b${searchText}`, 'i'); // Match the search term at the word boundary, case-insensitive
    const matches = Object.keys(existingUsers).reduce((acc, key) => {
      if (!searchText || searchRegex.test(key)) {
        if (!roleSearchValue.length || mode === modes.create || (existingUsers[key].roles && existingUsers[key].roles.some(item => roleSearchValue.includes(item)))) {
          if (!statusSearchValue.length || mode === modes.create || statusSearchValue.includes(existingUsers[key].UserStatus)) {
            acc[key] = existingUsers[key];
          }
        }
      }
      return acc;
    }, {});
  
    setFilteredUsers({ ...matches });
  }


  const switchMode = (newMode) => {
    /*
    * Switch mode and associated cleanup
    */
    setDisplayErrors(false)  // Turn off any validation errors
    setMode(newMode)  // Switch mode
    setConfirmDelete(false)  // Turn off the 'confirm delete' flag.
    setUploadErrors(null) // Clear any bulk upload errors.
    setUpdatingUser(false)

    if (newMode === modes.edit) {
      setRolesFilter(optionalRoles)
      setRolesFilterValue(useOptionalValue)
    } else {
      setRolesFilter(defaultRoles)
      setRolesFilterValue(useDefaultValue)
    }
  }

  const confirmDelete = (confirm) => {
    /*
    * Turn on 'confirm delete' and validate form
    */
    setDisplayErrors(true)
    if (!isFormValid) { return; } 
    setConfirmDelete(confirm)
  }

  const clearInput = () => {
    /*
    * Clear user input
    */
    setUsername('')
    setPassword('')
    setDisplayErrors(false)
  }

  const getUsers = async () => {
    /*
    * Get all users for this organization
    */
    try {
      await Util.get('UserManagement', '/users', {
        queryParams: {
          organization: organization
        }
      }).then(result => {
        clearInput()
        const userData = result.data.Users
        const roleData = result.data.roles
        let cleanedUserData = {}

        for (let x of userData) {
          if (!x.hasOwnProperty("Attributes")) { continue }
          
          let attributes = x["Attributes"]
          let username = ""
          for (let attribute of attributes) {
            if (!attribute.hasOwnProperty("Name")) { continue }

            if (attribute["Name"] == "email") {
              username = attribute["Value"]
            }

            if (username) { break }
          }
          username = username.toLowerCase()

          if (username) {
            x.UserStatus = x.UserStatus === "CONFIRMED" ? "Active" : new Date() - new Date(x.UserLastModifiedDate) < 7 * (1000 * 60 * 60 * 24) ? "Pending" : "Inactive"
            cleanedUserData[username] = x
          }
        }
        for (let x of roleData) {
          if (!x.hasOwnProperty("user") || !x.hasOwnProperty("Roles") ) { continue }
          
          let username = x["user"].toLowerCase()

          if (!cleanedUserData.hasOwnProperty(username)) { continue}

          cleanedUserData[username]["roles"] = x["Roles"]
        }
        
        setExistingUsers(cleanedUserData)

        if (!filteredUsers.length && mode !== modes.create) { setFilteredUsers(cleanedUserData)}

        Toast.show('✅ Users loaded', {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
      })});

    } catch (error) {
      setUpdatingUser(false)
      alert("Error", '❌ Error loading users\n', error)
    }
    setUpdatingUser(false)
  }

  const postCreateUser = async (new_user, temp_password, new_email, new_role) => {

    new_user = new_user.toLowerCase()
    new_email = new_email.toLowerCase()
    await Util.post('UserManagement', '/users', {
      body: {
        username: new_user,
        password: temp_password,
        email: new_email,
        organization: organization,
        roles: [new_role]
      }
    }).then(result => {
      existingUsers[new_email] = {'UserStatus': 'Pending', 'roles': [new_role]}
      filteredUsers[new_email] = {'UserStatus': 'Pending', 'roles': [new_role]}
      Toast.show('✅ User(s) created', {
        backgroundColor: "#259e23",
        position: Toast.positions.BOTTOM,
        duration: 2500,
        textColor: "#fff",
    })});
  }

  const createUser = async () => {
    /*
    * Create new user with temporary password
    */
    try {
      setDisplayErrors(true)
      if (!isFormValid) { return } 
      setUpdatingUser(true)

      await postCreateUser(username, password, email, rolesFilterValue)

      clearInput()

    } catch (error) {
      setUpdatingUser(false)
      alert("Error", '❌ Error creating user\n')
    }
    setUpdatingUser(false)
  }

  const editUser = async (username) => {
    /*
    * Edit user: Change password and/or role
    */
    try {
      if (!editUsers.hasOwnProperty(username)) { return }
      const editInfo = editUsers[username]

      let password = editInfo.hasOwnProperty('password') ? editInfo['password'] : ''
      let new_roles = editInfo.hasOwnProperty('roles') ? editInfo['roles'] : []
      if (!password.length && !new_roles.length) { return }
         
      let params = {username: username, email: username, organization: organization}
      if (password.length) {
        params['password'] = password
      }
      if (new_roles.length) {
        params['roles'] = new_roles
      }

      await Util.post('UserManagement', '/users/reset', {
        body: params
      }).then(result => {

        if (password.length > 0) {
          existingUsers[username]['UserStatus'] = 'Pending'
          filteredUsers[username]['UserStatus'] = 'Pending'
          
          console.log('send email!')
          const message = `Your username is ${username} and temporary password is ${password}`;
          const details =''
          Util.sendEmailNotification(details, message, 'passwordReset', organization, username)
          console.log('email sent')
        }
        if (new_roles) {
          existingUsers[username]['roles'] = new_roles
          filteredUsers[username]['roles'] = new_roles
        }

        Toast.show('✅ User modified', {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
      })});

    } catch (error) {
      setUpdatingUser(false)
      alert("Error", '❌ Error editing user\n')
    }
  }

  const deleteUser = (username) => {
    /*
    * Delete user
    */
    if (username in existingUsers) {
      delete existingUsers[username]
      delete filteredUsers[username]
    }
    resetSearch()
  }

  const savePasswordReset = (username, password) => {
    if (!editUsers.hasOwnProperty(username)) {
      editUsers[username] = {}
    }
    editUsers[username]['password'] = password
    setEditUsers(editUsers);
  }

  const saveRoleUpdate = (username, roles) => {
    if (!editUsers.hasOwnProperty(username)) {
      editUsers[username] = {}
    }
    editUsers[username]['roles'] = roles
    setEditUsers(editUsers);
  }

  const bulkCreateUsers = async () => {
    setFileLoading(true)
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: 'text/csv',
      });

      if (res.canceled) {
        setFileLoading(false)
        return
      }

      let csv = '';

      // Fetch the file content then parse the CSV
      const response = await fetch(res.assets[0].uri);
      csv = await response.text();
      const results = readString(csv)

      let temporary_password = null
      // Loop through the data and create users

      // Create each user. If any fail, construct an error message.
      let success_count = 0
      let error_count = 0
      let error_message = null
      
      for (let row of results.data )
      {
        let new_user = row[0].trim()
        if (!new_user) { continue }
        
        let new_role = row[1].trim()
        new_role = new_role && new_role.toLowerCase() in validRoles ? validRoles[new_role.toLowerCase()] : 'Reporter' 
        temporary_password = row[2] ? row[2] : temporary_password

        // Require at least the first user has a temporary password indicated.
        if (!temporary_password) {
          throw new Error("Temporary password must be set. Assign temporary password to at least the first new user.")
        }

        try {
            await postCreateUser(new_user, temporary_password.trim(), new_user, new_role)
            success_count += 1
        } catch (err) {
          error_count += 1
          error_message = error_message ? error_message + ", " + new_user : new_user
        }   
        
      }

      // Display status. Show number of successfully created users and list of failed users.
      setUploadErrors(error_message)
      alert(`Bulk upload attempt: \nUsers created: ${success_count} \nErrors: ${error_count}`)
      if (success_count > 0) {
        Toast.show(`✅ ${success_count} users created`, {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
        })
      }
    } catch (err) {
      setFileLoading(false)
      alert("Error", '❌ Error bulk-creating users\n')
    }    
    setFileLoading(false)
  };

  return (
    <View style={webStyles.container }> 
    <SafeAreaView style={styles.safeAreaContainer}>
      <Header organization={organization} admin={admin} />
      <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
      <View style={styles.container}>            
        <View style={styles.chip_container}>
          <Wrap shouldWrapChildren="true" spacing={6}>
            <Pressable onPress={() => switchMode(modes.create)}>
              <Box style = {mode === modes.create ? styles.ChipSelected : styles.Chip}>
                  <CustomText style = { styles.ChipText} font='medium' size={18} color={'#000'}>Create User</CustomText>
              </Box>
            </Pressable>
            <Pressable onPress={() => switchMode(modes.edit)}>
              <Box style = {mode === modes.edit ? styles.ChipSelected : styles.Chip}>
                  <CustomText style = { styles.ChipText} font='medium' size={18} color={'#000'}>Edit User</CustomText>
              </Box>
            </Pressable>
            <Pressable onPress={() => switchMode(modes.delete)}>
              <Box style = {mode === modes.delete ? styles.ChipSelected : styles.Chip}>
                  <CustomText style = { styles.ChipText} font='medium' size={18} color={'#000'}>Delete User</CustomText>
              </Box>
            </Pressable>
          </Wrap>
        </View>

        {(mode === modes.create ) && <AppTextInput
          value={username}
          onChangeText={text => {enterEmail(text)}}
          leftIcon="email"
          width='90%'
          placeholder="Enter email"
          autoCapitalize="none"
          keyboardType="email-address"
          textContentType="emailAddress"
          autoComplete="new-password" 
        />}
        <CustomText style={styles.error}>{displayErrors ? errors.email: null}</CustomText>

        {(mode === modes.create) && <AppTextInput
          value={password}
          onChangeText={text => setPassword(text)}
          leftIcon="lock"
          placeholder= {mode === modes.edit ? "Optionally reset temporary password (min 8 chars)" : "Enter temporary password (min 8 chars)"}
          autoCapitalize="none"
          autoCorrect={false}
          secureTextEntry
          textContentType="password"
          autoComplete="new-password" 
        />}
        <CustomText style={styles.error}>{displayErrors ? errors.password: null}</CustomText>

        {(mode === modes.create ) &&
          <View style={screenWidth >= 1000 ? {marginLeft: '5px', width: wp(40)} : {marginLeft: 1, marginBottom: 5, width: wp(80)}}>
            <DropDownPicker
              zIndex={9}
              multiple={false}
              min={0}
              open={rolesOpen}
              value={rolesFilterValue}
              items={rolesFilter}
              setOpen={setRolesOpen}
              setValue={setRolesFilterValue}
              setItems={setRolesFilter}
              placeholder="Keep current role"
              dropDownDirection="TOP"
              maxHeight={158}
           />
          </View>
        }

        { mode === modes.create && <AppButton title="Create User" isBusy={updatingUser} onPress={createUser}  />}

      {mode === modes.create && <AppButton title="Bulk Create" isBusy={fileLoading} onPress={bulkCreateUsers}/> }
      {mode === modes.create && uploadErrors && <CustomText style={styles.error}>Failed to create users: {uploadErrors}</CustomText>}

      {/* Search Users */}                
      {mode !== modes.create && <View>   <View style={screenWidth >= 1000 ? {marginTop: 15, marginLeft: '5px', width: wp(50)} : {marginTop: 15, marginLeft: 1, marginBottom: 5, width: wp(80)}}>
        <View style={styles.textboxContainer}> 
          <TextInput autoComplete="new-item" value={searchText} onChangeText={(text) => { setSearchText(text) }} placeholder="Search Users" placeholderTextColor={'rgba(0,0,0,0.35)'} style={styles.textboxText} />
          <Pressable style={styles.textboxResetSearch} onPress={resetSearch}>
            <Feather name="x" size={24 * scale} color={'rgba(0,0,0,0.35)'} />
          </Pressable>
        </View>
      </View>
      <View style={screenWidth >= 1000 ? {flexDirection: 'row', width: wp(50)} : {flexDirection: 'row', width: wp(80)}}>
      <View style={screenWidth >= 1000 ? {marginLeft: 5, width: '49%', height: hp(4)} : {marginLeft: 1, marginBottom: 5, height: hp(4), width: '100%'}}>
      <DropDownPicker
        style={{
          minHeight: 28,
          maxHeight: 28,
          marginTop: 5
        }}
        textStyle={{
          fontSize: 14
        }}
        badgeStyle={{
          height: 20
        }}
        customItemContainerStyle={{
          backgroundColor: "#dfdfdf"
        }}
        zIndex={9}
        multiple={true}
        min={0}
        open={roleSearchOpen}
        value={roleSearchValue}
        items={roleSearchFilter}
        setOpen={setRoleSearchOpen}
        setValue={setRoleSearchValue}
        setItems={setRoleSearchFilter}
        placeholder="Filter by Role"
        dropDownDirection="TOP"
        maxHeight={158}
           />
      </View>
      <View style={screenWidth >= 1000 ? {marginLeft: 5, height: hp(4), width: '50%'} : {marginLeft: 1, marginBottom: 5, height: hp(4), width: '100%'}}>
      <DropDownPicker
        style={{
          minHeight: 28,
          maxHeight: 28,
          marginTop: 5
        }}
        textStyle={{
          fontSize: 14
        }}
        badgeStyle={{
          height: 20
        }}

        zIndex={9}
        multiple={true}
        min={0}
        open={statusSearchOpen}
        value={statusSearchValue}
        items={statusSearchFilter}
        setOpen={setStatusSearchOpen}
        setValue={setStatusSearchValue}
        setItems={setStatusSearchFilter}
        placeholder="Filter by Status"
        dropDownDirection="TOP"
        maxHeight={158}
           />
      </View>
      </View>
      </View>}
      <ScrollView key={`${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`} style={screenWidth >= 1000 ? { height: 300, marginTop: 15, paddingTop: 20, marginBottom: 5, width: wp(50) } : { height: 300, paddingTop: 20, marginTop: 15, marginBottom: 5, width: wp(80) }}>
      {showUsers && <DataTable>
        {filteredUsers && <DataTable.Header style={{}}>
          <DataTable.Title style={{ minWidth: 200 }}>User</DataTable.Title>
          <DataTable.Title style={{ minWidth: 100, maxWidth: 100 }}>Status</DataTable.Title>
          <DataTable.Title>Roles</DataTable.Title>
          {(mode === modes.edit) && <DataTable.Title>Temporary Password</DataTable.Title>}
          {((mode === modes.edit) || mode === modes.delete) && <DataTable.Title></DataTable.Title>}
        </DataTable.Header>}
        {filteredUsers && Object.keys(filteredUsers).map((obj, index) => {
          return (
            <DataTable.Row style={{ cursor: 'default' }} key={index}>
              <DataTable.Title key={`${obj}-user-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`} style={{ minWidth: 200 }}>{obj}</DataTable.Title>
              <DataTable.Title style={{ minWidth: 100, maxWidth: 100 }} key={`${obj}-status-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`} >{filteredUsers[obj].UserStatus}</DataTable.Title>
              {mode === modes.edit && <DataTable.Cell key={`${obj}-roles-edit-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`} ><UserRoles user={obj} roles={filteredUsers[obj].roles} edit={true} postUpdateAction={saveRoleUpdate} /></DataTable.Cell>}
              {mode !== modes.edit && <DataTable.Cell key={`${obj}-roles-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`}><UserRoles user={obj} roles={filteredUsers[obj].roles} edit={false} /></DataTable.Cell>}
              {mode === modes.edit && <DataTable.Cell><ResetUserPassword user={obj} postUpdateAction={savePasswordReset} /></DataTable.Cell>}
              {mode === modes.delete && <DataTable.Cell key={`${obj}-delete-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`} numeric><DeleteUserButton user={obj} postDeleteAction={deleteUser} /></DataTable.Cell>}
              {mode === modes.edit && <DataTable.Cell key={`${obj}-edit-${searchText}-${roleSearchValue.join('-')}-${statusSearchValue.join('-')}`}  numeric><UpdateUserButton user={obj} organization={organization} editUsers={editUsers} postUpdateAction={editUser} /></DataTable.Cell>}
            </DataTable.Row>);
        })}
  </DataTable>}
</ScrollView>
      </View>
      </TouchableWithoutFeedback>
    </SafeAreaView>
    <Footer admin={admin} organization={organization} />
    </View>
  );
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white'
    },
    container: {
      flex: 1,
      alignItems: 'center'
    },
    error: {
      color: "red",
      fontWeight: "bold",
      textAlign: "center"
    },
    chip_container: {
      backgroundColor: "white",
      flex: 1,
      maxHeight: 40,
      marginTop: 5,
      marginBottom: 15
    },
    ChipText:{
      marginHorizontal: wp(2),
    },
    Chip:{
      marginHorizontal: wp(0),
      backgroundColor: 'rgba(200,200,200,0.3)',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      borderRadius: 100,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
    },
    ChipSelected:{
      marginHorizontal: wp(0),
      backgroundColor: '#FFC451',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      borderRadius: 100,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
    },
    textboxContainer: {
      flexDirection: 'row',
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      borderRadius: 8,
      width: '100%',
      height: hp(4),
      marginTop: hp(0),
      justifyContent: 'center',
    },
    textboxIconContainer: {
      flex: 1,
      backgroundColor: '#FFC451',
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
    },
    textboxResetSearch: {
      flex: 1,
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 50
    },
    textboxIconContainerEye: {
      backgroundColor: '#FFC451',
      borderRadius: 12,
      width: '9%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    textboxText: {
      flex: 4,
      fontFamily: fonts.POPPINS.regular,
      fontSize: 20 * scale,
      color: '#000',
      paddingLeft: 8,
      marginLeft: 4,
      width: '100%'
    },
  });

  export default UserManagement;