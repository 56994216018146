import React, { useEffect, useState } from "react"
import { View, Pressable, Platform, StyleSheet, ScrollView } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Textbox from "../components/Textbox";

import Constants from "expo-constants"
const appVersion = Constants.expoConfig.version
import AsyncStorage from '@react-native-async-storage/async-storage';
console.log("ExpoConfig: ",Constants.expoConfig)
console.log("appVersion: ", appVersion)

import * as Auth from 'aws-amplify/auth';
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";

import Util from "../util/util.js"

const Settings = (props) => {

    // Defining local state values
    const { organization, admin } = props.route.params;
    const [email, setEmail] = useState('Loading...');
    const [company, setCompany] = useState('');
    const [reports, setReports] = useState('Loading...');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        getUserReports()
        getUser()
    }, [])

    const getUser = async (_organization) => {
        const user = await Auth.fetchUserAttributes()
        setEmail(user.email);
    }

    async function getUserReports(){
      let routing = JSON.parse(await AsyncStorage.getItem("routing"))
      let routingData = Object.values(routing)

        try { 
          const requestData = {
            queryParams: {
              organization: organization,
              organizations: routingData.length ? organization + "," + routingData.toString() : organization
            }
          }
          try{ 
            const data = await Util.get('Resty', '/items/listUserObjects/:user', requestData)
            setReports(data.items.length.toString())
          }
          catch(error){
            console.log(error)
            //setText(error.toString());
          }
        } catch (e) {
          console.log("failed2: ",e.message);
        }
      };

    return (
        <ScrollView style={Platform.OS === 'web' ? webStyles.container : mobileStyles.container} contentContainerStyle={{ flexGrow: 1 }}>
            {/* Global header  */}
            <Header organization={organization} admin={admin} />
            <CustomText font='medium' size={15} color={'#000'}>App Version: {appVersion}</CustomText>

            <View style={{ flex: 1, paddingHorizontal: wp(6), paddingTop: hp(1.7) }}>
                {/* Email textbox  */}
                <View style={{ paddingBottom: hp(2) }} pointerEvents="none">
                    <CustomText style={{ textAlign: 'center' }} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Email</CustomText>
                    <Textbox adjustsFontSizeToFit={true} allowFontScaling={false} value={email} editable = {false} />
                </View>
                {/* Report number textbox  */}
                <View style={{ paddingBottom: hp(2.5) }} pointerEvents="none">
                    <CustomText style={{ textAlign: 'center' }} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Number of reports</CustomText>
                    <Textbox keyboardType='numeric' value={reports} editable = {false} />
                </View>
                {/* Change password button  */}
                <Pressable onPress={() => { props.navigation.navigate('ForgotPasswordInternal', {organization: organization, admin: admin} )}}>
                    <View style={styles.button}>
                        <CustomText font='medium' size={24} color={'#000'}>Change password</CustomText>
                    </View>
                </Pressable>
            </View>
            <Footer admin={admin} organization={organization} />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(8),
        width: '70%',
        alignSelf: 'center'
    }
})

export default Settings;