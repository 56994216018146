import {React, useState } from 'react';
import { Platform, StyleSheet, Text, Pressable, Dimensions, ActivityIndicator} from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppButton from '../components/AppButton.js'
import Toast from 'react-native-root-toast'
import alert from '../components/alert.js'

import Util from "../util/util.js"
import { getExpoPushTokenAsync } from 'expo-notifications';

const screenWidth = Dimensions.get('window').width


export default function DeleteUserButton({ user, postDeleteAction }) {
  const [username, setUsername] = useState(user)
  const [confirmed, setConfirm] = useState(false)
  const [updatingUser, setUpdatingUser] = useState(false);


  const deleteUser = async () => {
    /*
    * Delete user
    */
    try {
      setUpdatingUser(true)
      setConfirm(false)

      await Util.post('UserManagement', '/users/delete', {
        body: {
          username: username
        }
      }).then(result => {

        postDeleteAction(username)

        Toast.show('✅ User deleted', {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
      })});

    } catch (error) {
      setUpdatingUser(false)
      alert("Error", '❌ Error deleting user\n')
    }
    setUpdatingUser(false)
  }

  return (
    <AppButton 
      title={confirmed ? "Confirm Delete" :  "Delete"} 
      isBusy={updatingUser} 
      onPress={confirmed ? deleteUser : () => setConfirm(true)}  
      buttonStyle={styles.button}
      textStyle={styles.buttonText}
    />
  );
}
const styles = StyleSheet.create({
  button: {
    cursor: 'pointer',
    marginVertical: 10,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: 95,
    backgroundColor: '#FFC451'
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '600',
  }
});